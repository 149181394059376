import React from 'react';

const InfoSvg: React.FC<{ className?: string }> = ({ className }) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2_242)">
      <path d="M17.9926 0C27.9703 0.0446097 35.9926 8.01487 36 18C36 27.9703 28 35.9926 18.0074 36C7.98513 36 0 27.9554 0 18.0074C0 8.04461 8 0.0520446 17.9926 0ZM20.6022 25.0409C20.4981 25.1822 20.4164 25.3011 20.3346 25.4052C19.9554 25.8736 19.5762 26.3494 19.1822 26.8104C19.0558 26.9591 18.8848 27.0855 18.7063 27.1896C18.4535 27.3383 18.1859 27.2565 18.0818 26.9814C18.0223 26.8253 18.0074 26.632 18.0372 26.4684C18.0744 26.2082 18.1636 25.9554 18.2379 25.7026C19.2714 22.1041 20.2974 18.513 21.3309 14.9145C21.3606 14.8253 21.3755 14.7286 21.4052 14.6022C19.3234 14.9368 17.2788 15.2639 15.2268 15.5911C15.197 15.7918 15.1673 15.9777 15.1375 16.171C15.4275 16.2007 15.6877 16.2156 15.9554 16.2454C16.4461 16.3048 16.7509 16.6617 16.7212 17.1599C16.7063 17.4126 16.6617 17.6654 16.5948 17.9033C16.0074 19.9851 15.4126 22.0595 14.8253 24.1413C14.5651 25.0558 14.2974 25.9628 14.0967 26.8922C13.8067 28.223 14.6691 29.2937 16.0297 29.3383C16.7361 29.3606 17.3829 29.197 17.9926 28.855C18.5725 28.5279 19.0632 28.0892 19.5093 27.5985C20.0595 26.9814 20.5204 26.3048 20.9442 25.5985C20.9963 25.5167 21.0335 25.4275 21.0781 25.3309C20.9219 25.2342 20.7807 25.1524 20.6022 25.0409ZM21.9554 8.92193C21.9554 7.69517 20.9814 6.69145 19.7546 6.67658C18.5056 6.66915 17.487 7.64312 17.487 8.89219C17.487 10.3717 18.6766 11.1673 19.7026 11.1673C20.9294 11.1673 21.948 10.1561 21.9554 8.92193Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2_242">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default InfoSvg;